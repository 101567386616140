import axios from "axios";
import { BASE_URL, SECONDARY_URL } from "../Config";

const isInmotion = window.location?.pathname?.includes('inmotion')

const fetchSecurityQuestion = async (phone,role) => {
	const response = await axios.get(
    `${isInmotion ? SECONDARY_URL : BASE_URL}/withdrawals/wallet-question/${phone}/${role}`
  );
	
  return response?.data;
};

const answerWalletQuestion = async (phone,answer,role) => {
	const response = await axios.get(
    `${isInmotion ? SECONDARY_URL : BASE_URL}/withdrawals/wallet-answer/${phone}/${answer}/${role}`
  );
	
  return response?.data;
};  

const resetWalletPin = async (data) => {
	const response = await axios.put(`${isInmotion ? SECONDARY_URL : BASE_URL}/withdrawals/wallet-pin-reset`, data);
	
  return response?.data;
};

const WalletServices = { 
	fetchSecurityQuestion,
	answerWalletQuestion,
	resetWalletPin,
};

export default WalletServices;
