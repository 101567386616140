import { Controller } from "react-hook-form";
import React from "react";
import { UnfilledStar } from "../../assets/svg/UnfilledStar";

const RatingInputField = ({isRequired, handleChange, value, containerStyles, error}) => {
  const isChosen = index => {
    return index <= value;
  };

  return(
    <div className={`${containerStyles}`} >

        <p className='text-lg w-fit relative' >Rating
          {isRequired && <span className='absolute right-[-10] bottom-1 text-[red] w-fit' >*</span>}
        </p>
      <p className='opacity-60 m-0 p-0 text-xs' >What is your rating?</p>
      {new Array(5).fill(0).map((_, i) => (
        <button onClick={() => handleChange(i + 1)} type='button' key={i} >
          <UnfilledStar
            key={i}
            className={isChosen(i + 1) ? 'fill-[#FFD60A]' : 'opacity-10'}
            size={7}
          />
        </button>
      ))}
      {error?.message ? (
        <p className="text-sm text-[red] mx-auto"> {error?.message} </p>
      ) : null}
    </div>
  )

}

export const ControlledRating = ({control, name, rules, ...rest}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({field: {value, onChange}, fieldState: {error}}) => (
      <RatingInputField
        value={value}
        handleChange={onChange}
        error={error}
        {...rest}
      />
    )}
  />
);




