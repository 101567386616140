import { BASE_URL } from "../Config";
import axios from "axios";

/**
 * @description Creates general feedback
 * @param {GeneralFeedback} data
 * @returns
 */
const createGeneralFeedback = async data => {
  const response = await axios.post(`${BASE_URL}/user/feedback`, data);
  return response.data;
};
/**
 * @description Creates specific package feedback
 * @param {PackageFeedback} data
 * @returns
 */
const createPackageFeedback = async data => {
  const response = await axios.post(
    `${BASE_URL}/user/package/feedback?channel=web`,
    data,
  );
  return response.data;
};
/**
 * @typedef {{
 *  rating: number,
 *  accessPoint?: string,
 *  service?: string,
 *  feedback: string,
 * }} GeneralFeedback
 */
/**
 * @typedef {Object} PackageFeedback
 * @extends GeneralFeedback
 * @property {string} packageId
 * @property {string} packageType
 */


const fetchPackageFeedback = async (packageId, packageType) => {
  const response = await axios.get(`${BASE_URL}/package-feedback?id=${packageId}&type=${packageType}&channel=web`);
  return response?.data;
};

const FeedbackServices = {
  createGeneralFeedback,
  createPackageFeedback,
  fetchPackageFeedback
};
export default FeedbackServices;
