import React from "react";
import { UnfilledStar } from "../../../assets/svg/UnfilledStar";
import usePackageRating from "../../../hooks/usePackageRating";

const PackageRating = () => {
  const { rating } = usePackageRating();

  if (!rating) {
    return null;
  }
  const isChosen = (index) => {
    return index <= rating?.rating;
  };
  return (
    <div className="p-4 rounded-2xl bg-white border border-gray-200 w-full overflow-wrap ">
      <p className="font-bold mb-2">Package Review</p>
      <div className="flex flex-row items-center justify-start">
        {new Array(5).fill(0).map((_, i) => (
          <UnfilledStar
            key={i}
            className={isChosen(i + 1) ? "fill-[#FFD60A]" : "opacity-10"}
            size={7}
          />
        ))}
        <p className="ml-1"> {`${rating?.rating}.0`} </p>
      </div>

      <p className="mt-2 text-[13px] line-clamp-5">{rating?.feedback}</p>
    </div>
  );
};

export default PackageRating;
