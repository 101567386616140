import React from "react";
import Button from "./Button";

/**
 * @param {{title:string,subtitle:string,fields:{name:string,type:string,value:string,placeholder:string,handleChange:Function}[],
 *  handleSubmit:Function,
 *  options:{title:string,handleClick:Function}
 * }} props
 * @returns
 */
const ResetForm = (props) => {
  return (
    <div className="bg-white h-screen md:h-auto p-5 md:rounded-xl w-full sm:w-3/4 md:w-1/2 lg:w-1/3  flex flex-col items-center gap-3 font-inter">
      {/* Title */}
      <h3 className={"text-4xl font-bold text-inmotion_primary text-center"}>
        {props.title}
      </h3>

      <p className="text-center text-sm md:text-base text-slate-600">
        {props.subtitle}
      </p>

      <form
        className="w-full flex flex-col mt-5 gap-3"
        onSubmit={props.handleSubmit}
      >
        {props.fields?.map((field) => (
          <div className="w-[99%] md:w-11/12 mx-auto">
            <label htmlFor={field?.label ? "" : "hidden"}>{field?.label}</label>
            <br className={field?.label ? "" : "hidden"} />
            <input
              type={field.type}
              name={field.name}
              placeholder={field?.placeholder}
              value={field?.value}
              onChange={field.handleChange}
              required={field?.required}
              maxLength={field?.maxLength}
              className={
                "border border-inmotion_primary rounded p-3 w-full mt-1"
              }
            />
          </div>
        ))}

        <div
          className={
            props?.options?.length === 0
              ? "hidden"
              : "mt-5 w-[99%] md:w-11/12 mx-auto"
          }
        >
          {props?.options?.map((field) => (
            <button
              type="button"
              onClick={field.handleClick}
              className="text-left text-inmotion_primary font-medium"
            >
              {field?.title}
            </button>
          ))}
        </div>

        <Button
          isLoading={props?.isLoading}
          loadingText={props?.loadingText}
          disabled={props?.isLoading || props?.isDisabled}
          type="submit"
          className={
            "bg-inmotion_primary mt-8 w-[99%] md:w-11/12 mx-auto p-3 text-white text-lg font-medium"
          }
          text={"Submit"}
        />
      </form>
    </div>
  );
};

export default ResetForm;
