import React, { useState } from "react";

const FrequentQuestions = () => {
  const [openList, setOpenList] = useState([]);
  return (
    <div className={"mt-8 md:mt-20 xl:mx-28  items-center mx-2"} id={"faq"}>
      {/*  title  */}
      <h1
        className={
          "mb-0 md:mb-5 font-times text-[28px] md:text-[48px] font-[600] text-black text-center md:text-left"
        }
      >
        Frequently Asked Questions
      </h1>

      <div
        className={
          " flex flex-col md:grid grid-cols-1 md:grid-cols-2 md:gap-x-5 gap-y-3 px-4 py-5 md:py-0"
        }
      >
        <div className={"flex-1 flex flex-col gap-y-3"}>
          <FaqItem
            quiz={"How does Pick up Mtaani work? "}
            isOpen={openList?.includes(0)}
            handlePress={() => {
              if (openList?.includes(0)) {
                setOpenList(openList.filter((item) => item !== 0));
              } else setOpenList((prevState) => [...prevState, 0]);
            }}
          >
            <p className={"mb-2"}>We offer 4 services.</p>

            <ul
              className={"flex flex-col gap-y-1 list-decimal px-4"}
              id={"faq"}
            >
              <li>
                Agent delivery - collection from our 190+ network of agents
              </li>
              <li>
                Doorstep delivery - delivery to the client's home/office and pay
                on delivery services
              </li>
              <li>
                Errand - We run errand to send parcel via 3rd party courier
                services such as Easy couch, 2nk, etc. We also facilitate
                collections withing Nairobi.
              </li>
              <li>
                Rent a shelf - We offer storage services at a monthly fee per
                shelf or daily rate per package
              </li>
            </ul>
          </FaqItem>
          <FaqItem
            quiz={
              "What happens if a parcel exceeds the 3-day collection period? "
            }
            isOpen={openList?.includes(1)}
            handlePress={() => {
              if (openList?.includes(1)) {
                setOpenList(openList.filter((item) => item !== 1));
              } else setOpenList((prevState) => [...prevState, 1]);
            }}
          >
            <p className="">
              If a parcel exceeds the 3-day collection period, it is usually
              returned to our main office, and the receiver is notified to
              collect it within 7 days, if the 7 days elapse one has not
              collected his or her parcel, one will be notified again to pick it
              up within 3days, failure to do so we will dispose the parcel at
              our convenience.
            </p>
          </FaqItem>
          <FaqItem
            quiz={"What are your delivery schedules? "}
            isOpen={openList?.includes(2)}
            handlePress={() => {
              if (openList?.includes(2)) {
                setOpenList(openList.filter((item) => item !== 2));
              } else setOpenList((prevState) => [...prevState, 2]);
            }}
          >
            <p className={"mb-2"}>
              We have 3 delivery schedules at the CBD from Monday to Saturday:
            </p>
            <ul className={"flex flex-col gap-y-1 list-decimal px-4"}>
              <li>Morning 9:30 to 1 p.m</li>
              <li>Midday 1:30 to 4:30 p.m.</li>
              <li>Evening 5:00 to 8 p.m.</li>
            </ul>

            <p className={"mb-2"}>
              Delivery is within 2-3hrs from the dispatch schedule
              <br />
              When sending from MTAANI the schedule is as below:
              <br />
              <br />
              For any parcels dropped before 10 am delivery is between 2 pm to
              4:30 pm Any parcel dropped after 10 am but before 2 pm delivery is
              between 5 pm to 8 pm. After 2 pm next day delivery
            </p>
          </FaqItem>
          <FaqItem
            quiz={"What if my package is damaged "}
            isOpen={openList?.includes(3)}
            handlePress={() => {
              if (openList?.includes(3)) {
                setOpenList(openList.filter((item) => item !== 3));
              } else setOpenList((prevState) => [...prevState, 3]);
            }}
          >
            <p className=" my-2">
              If you receive your package damaged you are required to report to
              our customer service team via DM, Whatsapp, or Call while still at
              the Pickup point/agent location. Share an image of the damaged
              product.
            </p>
            <p className=" my-2">
              Tampered packaging is to be reported immediately to our customer
              service team via DM, WhatsApp, or Call while still at the agent
              during collection. Share an image of how you’ve received the
              package.
            </p>
            <p className=" my-2">
              Pick Up Mtaani refrains from delivery of fragile products,
              delivery is at the owners’ risk. The company shall not be held
              liable for any damage claim.
            </p>
          </FaqItem>
          <FaqItem
            quiz={"Where are your offices located?"}
            isOpen={openList?.includes(4)}
            handlePress={() => {
              if (openList?.includes(4)) {
                setOpenList(openList.filter((item) => item !== 4));
              } else setOpenList((prevState) => [...prevState, 4]);
            }}
          >
            <p>Our CBD locations:</p>

            <ul className={"flex flex-col gap-y-1 list-decimal px-4"}>
              <li>Star mall 3rd floor rm c14</li>
              <li>
                {" "}
                Philadelphia house next to Afya center 3rd-floor wing A at Pick
                Up Mtaani
              </li>
              <li>
                Operational hours: Mon-Fri 8:30 am-7 pm and Sat 9:30 am-6 pm.
                Closed on Sunday
              </li>
            </ul>
          </FaqItem>
        </div>

        <div className={"flex-1 flex flex-col gap-y-3"}>
          <FaqItem
            quiz={"What is your refund policy? "}
            isOpen={openList?.includes(5)}
            handlePress={() => {
              if (openList?.includes(5)) {
                setOpenList(openList.filter((item) => item !== 5));
              } else setOpenList((prevState) => [...prevState, 5]);
            }}
          >
            <p className="">
              To process any refund the following documents are to be presented;
            </p>

            <ul className={"flex flex-col gap-y-1 list-decimal px-4"}>
              <li>
                {" "}
                Mpesa statement of the purchase transaction from both the seller
                and buyer
              </li>
              <li>
                Image representation of the product on the seller’s
                Instagram/Facebook page.
              </li>
            </ul>
            <p className={"mt-2"}>
              The company reserves a right to decline any claim if the above
              documents are not presented or present any evidence of alterations
            </p>
          </FaqItem>
          <FaqItem
            quiz={"What do you deliver? "}
            isOpen={openList?.includes(6)}
            handlePress={() => {
              if (openList?.includes(6)) {
                setOpenList(openList.filter((item) => item !== 6));
              } else setOpenList((prevState) => [...prevState, 6]);
            }}
          >
            <p className="">
              {" "}
              We currently deliver non-perishable goods that aren’t too bulky
              such as Cosmetics, clothing, shoes, and any other products that
              qualify{" "}
            </p>
            <p className=""> Items we do not deliver </p>
            <ul className={"flex flex-col gap-y-1 list-decimal px-4"}>
              <li> money</li>
              <li> food, flowers, and groceries</li>
              <li> explosives, ammunition, and flammable items</li>
              <li> Electronics i.e phones, laptops, tv, microwave, etc</li>
              <li> pets, ashes, and human remains</li>
              <li>
                {" "}
                Some fragile products such as gift boxes, some glass or
                breakable items
              </li>
              <li>
                {" "}
                Bulky and/or heavy packages suitcases or big boxed items, big
                pillows, etc
              </li>
            </ul>
            <br />
            <p className=""> Note: </p>
            <p className="">
              {" "}
              Cosmetics products made of glass packaging should have bubble wrap
              or any form of protective layer{" "}
            </p>
          </FaqItem>
        </div>
      </div>
    </div>
  );
};

export default FrequentQuestions;

const FaqItem = ({ quiz, isOpen, children, handlePress }) => (
  <div
    className={" bg-white border border-gray-200 p-2.5 md:p-4 rounded-[14px]"}
  >
    <div className={" flex justify-between items-center"}>
      <span className={"font-lato text-sm md:text-base font-[700]"}>
        {quiz}
      </span>

      {!isOpen ? (
        <button
          className={
            "bg-warning_50 text-warning_500 text-xl flex justify-center items-center h-8 w-8 rounded-md"
          }
          onClick={handlePress}
        >
          +
        </button>
      ) : (
        <button
          className={
            "bg-slate-50 text-slate_500 text-xl flex justify-center flex-shrink-0 items-center h-8 w-8 rounded-md"
          }
          onClick={handlePress}
        >
          -
        </button>
      )}
    </div>

    {isOpen && (
      <div className={"pt-2 text-grey_600 text-[13px] md:text-sm font-inter"}>
        {children}
      </div>
    )}
  </div>
);
