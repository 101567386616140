import axios from "axios";
import { BASE_URL, SECONDARY_URL } from "../Config";

/**
 *
 * @param {{name:string, phone:string, message:string, email:string}} body
 * @returns {Promise<*>}
 * @desc sends email
 */
const sendEmail = async (body) => {
  const isSecondary = window.location.pathname.includes("inmotion");
  const response = await axios.post(
    `${isSecondary ? SECONDARY_URL : BASE_URL}/contactus`,
    body
  );

  return response?.data;
};

const getSupportContacts = async () => {
  const response = await axios.get(`${BASE_URL}/support-contacts`);
  return response?.data?.contacts;
};
const ContactServices = { sendEmail, getSupportContacts };

export default ContactServices;
