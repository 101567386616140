import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useMemo } from "react";
import { Context } from "../../context/StorageContext";
import { useQuery } from "react-query";
import TrackingServices from "../../services/TrackingServices";
import { toast } from "react-toastify";
import Helper from "../../utils/Helper";
import { divIcon, Icon } from "leaflet";
import { useMap } from "react-leaflet/hooks";
import { Marker, Popup } from "react-leaflet";

export const MapMarker = (props) => {
  const { pkgDetails } = props;
  const { search } = useLocation();
  const trackingId = search.split("?")?.at(1);
  const { setTrackingData } = useContext(Context);
  const path = useLocation();

  const { data } = useQuery({
    queryKey: ["tracker-updates", trackingId],
    queryFn: () => TrackingServices.trackPackage(trackingId, path.pathname),
    onSuccess: (res) => {
      setTrackingData(res.packageDetails);
    },
    onError: (err) => {
      toast.error(
        "Tracking error. " + err.response.data?.message || err.message
      );
    },
    retry: false,
    refetchInterval: 2000,
  });

  const pkg = data?.packageDetails;
  const navigate = useNavigate();

  const currentAgent = useMemo(() => {
    return Helper.getCurrentAgent(pkg?.status, pkgDetails);
  }, [pkg?.status, pkgDetails]);

  // gets package tracking state(agent/rider/warehouse)
  const packageState = useMemo(
    () => Helper.getCurrentStage(pkg?.status),
    [pkg?.status]
  );

  const riderIcon = new divIcon({
    html: '<div><img src="/images/RIDER.png" class="rider-movement"/></div>',
  });

  const warehouseIcon = new Icon({
    iconUrl: "/images/marker.png",
    iconSize: [50, 65], // size of the icon
    popupAnchor: [0, -40],
  });

  const agentIcon = new Icon({
    iconUrl: "/images/marker.png",
    iconSize: [50, 65], // size of the icon
    popupAnchor: [0, -40],
  });

  const shelfIcon = new Icon({
    iconUrl: "/images/marker.png",
    iconSize: [50, 65],
    popupAnchor: [0, -40],
  });

  const defaultIcon = new Icon({
    iconUrl: "/images/marker.png",
    iconSize: [50, 65],
    popupAnchor: [0, -40],
  });

  const theIcon = (packageState) => {
    if (!packageState) {
      return defaultIcon;
    }
    if (packageState === "rider") {
      return riderIcon;
    } else if (packageState === "warehouse") {
      return warehouseIcon;
    } else if (packageState.trim() === "agent") {
      return agentIcon;
    } else {
      return defaultIcon;
    }
  };

  const RecenterAutomatically = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      if (lat) {
        try {
          map.setView([lat, lng], map.getZoom(), {
            animated: true,
            duration: 1,
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        //console.log(lat, lng)
      }
    }, [lat, lng]);
    return null;
  };

  return (
    <>
      <RecenterAutomatically
        lat={pkg?.location?.lat}
        lng={pkg?.location?.lng}
      />
      {packageState && pkg?.location?.lat && pkg?.location?.lng ? ( // added a check for the packages without geo-coordinates
        <Marker
          position={{
            lat: Number(pkg?.location?.lat),
            lng: Number(pkg?.location?.lng),
          }}
          icon={theIcon(packageState)}
          style={{
            border: "1px solid black",
          }}
        >
          <Popup
            position={{
              lat: Number(pkg?.location?.lat),
              lng: Number(pkg?.location?.lng),
            }}
          >
            <div
              style={{
                opacity: 0.75,
                padding: 3,
              }}
            >
              <div>
                <span className="font-bold">{currentAgent?.business_name}</span>

                <h6 className=" mt-2">{currentAgent?.agent_locations?.name}</h6>
                <div className={"mt-2"}>
                  <button
                    onClick={() => navigate(`/agent/${currentAgent?.id}`)}
                    className="py-1 px-2 justify-center bg-slate-500 text-white rounded-sm hover:scale-[0.995] hover:text-xs hover:font-semibold duration-300"
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </Popup>
        </Marker>
      ) : (
        pkg?.category === "shelf" && (
          <Marker
            position={{
              lat: pkg?.location?.lat,
              lng: pkg?.location?.lng,
            }}
            icon={theIcon(packageState)}
          >
            <Popup
              position={{
                lat: Number(pkg?.location?.lat),
                lng: Number(pkg?.location?.lng),
              }}
            >
              <div
                style={{
                  opacity: 0.75,
                  padding: 3,
                }}
              >
                <div>
                  <span className="font-bold">
                    {currentAgent?.business_name}
                  </span>

                  <h6 className=" mt-2">
                    {currentAgent?.agent_locations?.name}
                  </h6>
                  <div className={"mt-2"}>
                    <button
                      onClick={() => navigate(`/agent/${currentAgent?.id}`)}
                      className="py-1 px-2 justify-center bg-slate-500 text-white rounded-sm hover:scale-[0.995] hover:text-xs hover:font-semibold duration-300"
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        )
      )}
    </>
  );
};
