import { createContext, useState } from "react";

export const Context = createContext();

const StorageContext = ({ children }) => {
  /** @type {{access_token?:String, refresh_token?:String}}  */
  const [trackingData, setTrackingData] = useState({});
  const [packageDetails, setPackageDetails] = useState({});
  const [zoom, setZoom] = useState(17);

  return (
    <Context.Provider
      value={{
        trackingData,
        setTrackingData,
        packageDetails,
        setPackageDetails,
        zoom,
        setZoom,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default StorageContext;
