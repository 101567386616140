export const PACKAGE_STATES = {
    createdAgentShelfRedelivery: 'pending-shelf-agent', // shelf to agent redelivery done, order request not approved by shelf provider
    createdAgentStockRedelivery: 'pending-stock-agent', // stock to agent redelivery done, order request not approved by shelf provider
    createdDoorstepShelfRedelivery: 'pending-shelf-doorstep', //shelf to doorstep redelivery done, order request not approved by shelf provider
    createdDoorstepStockRedelivery: 'pending-stock-doorstep', // stock to doorstep redelivery done, order request not approved by shelf provider
    createdErrandShelfRedelivery: 'pending-shelf-errand', // shelf to errand redelivery done, order request not approved by shelf provider
    createdErrandStockRedelivery: 'pending-stock-errand', //stock to errand redelivery done, order request not approved by shelf provider
    created: 'request', // package created by vendor
    assigned: 'assigned', // package dropped to agent and assigned to rider
    onTransit: 'on-transit', // package picked by rider-1 from sender agent
    inTransitToExternal: 'in_transit_to_external_provider', // cross county package on delivery to warehouse 2 from warehouse 1
    droppedToWarehouse: 'dropped', // package dropped by rider to warehouse
    receivedWarehouse: 'recieved-warehouse', // package received by warehouse from rider
    assignedWarehouse: 'assigned-warehouse', // package assigned to rider-2 by warehouse
    warehouseTransit: 'warehouse-transit', // package picked by rider-2 from warehouse
    delivered: 'delivered', // package delivered to destination(customer/receiver-agent)
    collected: 'collected', // package collected by customer from receiver-agent
    withExternal: 'with_external_provider', // package delivered to county 2
    rejected: 'rejected',
    returned: 'return',
    earlyCollection: 'early_collection',
    booked: 'booked',
    pickedFromSeller: 'picked-from-seller',

    returnRequest: 'return_request',
    returnTransit: 'return_transit',
    returnDelivered: 'return_delivered',
    returnCollected: 'return_collected',
    returnDropped: 'return_dropped',
    returnAssigned: 'return_assigned',
    returnReceived: 'return_received',
};
