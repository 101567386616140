import React from "react";

export const UnfilledStar = ({className}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 11 11"
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55844 0.957305L3.30944 3.4833L0.522437 3.8893C0.409701 3.90582 0.303829 3.95353 0.216778 4.02704C0.129728 4.10056 0.0649676 4.19695 0.0298117 4.30533C-0.00534412 4.41371 -0.00949478 4.52976 0.0178286 4.64037C0.0451519 4.75099 0.102861 4.85176 0.184437 4.9313L2.20144 6.8963L1.72444 9.6713C1.70522 9.78347 1.71776 9.89878 1.76065 10.0042C1.80353 10.1096 1.87505 10.2009 1.96712 10.2678C2.05919 10.3347 2.16814 10.3745 2.28165 10.3827C2.39515 10.3909 2.5087 10.3672 2.60944 10.3143L5.10344 9.0043L7.59644 10.3183C7.69721 10.3708 7.81065 10.3942 7.92398 10.3858C8.03732 10.3774 8.14607 10.3376 8.23802 10.2708C8.32996 10.204 8.40145 10.1129 8.44445 10.0077C8.48745 9.90249 8.50026 9.78738 8.48144 9.6753L8.00444 6.8963L10.0204 4.9313C10.102 4.85176 10.1597 4.75099 10.187 4.64037C10.2144 4.52976 10.2102 4.41371 10.1751 4.30533C10.1399 4.19695 10.0751 4.10056 9.98809 4.02704C9.90104 3.95353 9.79517 3.90582 9.68244 3.8893L6.89644 3.4833L5.65044 0.957305C5.60043 0.853948 5.52231 0.766775 5.42503 0.705777C5.32775 0.64478 5.21526 0.612427 5.10044 0.612427C4.98562 0.612427 4.87312 0.64478 4.77584 0.705777C4.67857 0.766775 4.60045 0.853948 4.55044 0.957305H4.55844Z"
      />
    </svg>
  );
};
