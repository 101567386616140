const COLORS = {
  primary: "#FCDA45",
  grey_900: "#101828",
  grey_700: "#344054",
  grey_800: "#1D2939",
  grey_600: "#475467",
  grey_500: "#667085",
  grey_300: "#D0D5DD",
  grey_200: "#EAECF0",
  grey_100: "#F2F4F7",
  warning_50: "#FFFAEB",
  primary_300: "#FCDA45",
  primary_100: "#FEF7C3",
  warning_500: "#E9AC09",
  white_bg: "#FBFCFE",

  // inmotion primary
  inmotion_primary: "#90278C",
};

export { COLORS };
