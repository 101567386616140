import React from "react";
import InmotionLayout from "../../layout/inmotion";
import { EMAILS } from "../../constants/contacts";

const PrivacyPolicy = () => {
  return (
    <InmotionLayout className={"center"}>
      <div className="lg:max-w-[1200px] bg-white min-h-screen w-full pt-8 px-8 md:pt-16 md:px-16 ">
        <h2
          className={
            "text-[22px] md:text-3xl font-[700] text-center underline mb-3 md:mb-5 text-inmotion_primary"
          }
        >
          In Motion Delivery Privacy Policy
        </h2>

        <div>
          <p className="wow fadeInUp mb-5 text-base text-body">
            Welcome to Inmotion Delivery! This Privacy Policy explains how
            Inmotion Delivery Kenya Ltd ("we," "us," or "our") collects, uses,
            shares, and protects the personal information you provide or that we
            collect when you use our mobile application ("App") and related
            services ("Services") within the jurisdiction of Kenya. By using the
            Inmotion Delivery App and Services in Kenya, you consent to the
            practices described in this Privacy Policy.
          </p>

          <div>
            <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
              1. Information We Collect
            </h3>

            <ul className="wow fadeInUp mb-5 pl-3 md:pl-5 space-y-1">
              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Personal Information
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may collect various types of personal information that you
                  voluntarily provide to us, such as your name, email address,
                  phone number, postal address, date of birth, and other contact
                  details when you sign up for an account, create a profile, or
                  use specific features of the App
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Transaction Information
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  If you use our Services to make or receive payments, we
                  collect transaction details such as purchase history, payment
                  method details, and billing information.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Location Information
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  Inmotion Delivery requires access to your device's location
                  services to enable accurate service. We collect your precise
                  location information when you use the App to connect with
                  local pickup and delivery services. We may also collect your
                  IP address and approximate location through browser or device
                  settings. We obtain geolocation data from users of the
                  Inmotion Delivery rider app through background tracking to
                  acquire real-time information regarding the precise location
                  of Inmotion Delivery’s riders. This ensures accurate
                  estimations for both pickups and drop-offs of packages.
                  Additionally, it aids Inmotion Delivery in upholding rider
                  safety, optimizing routes, addressing and reporting any
                  potential delays, and guaranteeing punctual deliveries,
                  thereby enhancing overall service efficiency. Our stringent
                  data privacy policy is applicable to all Inmotion Delivery
                  personnel, and your geolocation data is securely stored and
                  exclusively accessible to authorized team members solely for
                  operational purposes. We remain dedicated to respecting your
                  privacy and affording you complete control over your location
                  settings within the Inmotion Delivery rider app
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Device and Usage Information
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We automatically collect information about the devices you use
                  to access our Services, including device identifiers, device
                  type, operating system, browser type, language preferences,
                  and other usage data to improve the performance and user
                  experience of the App.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Cookies and similar Information
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  Inmotion Delivery uses cookies, web beacons, pixels, and
                  similar technologies to enhance your experience and collect
                  information about your interactions with the App and Services.
                  Cookies are small text files stored on your device that track
                  your activities on the App, preferences, and browsing history.
                  You can manage your cookie preferences through your browser
                  settings
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Cookies and similar Information
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  Inmotion Delivery uses cookies, web beacons, pixels, and
                  similar technologies to enhance your experience and collect
                  information about your interactions with the App and Services.
                  Cookies are small text files stored on your device that track
                  your activities on the App, preferences, and browsing history.
                  You can manage your cookie preferences through your browser
                  settings
                </p>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
              2. HOW WE USE YOUR INFORMATION
            </h3>

            <ul className="wow fadeInUp mb-5 pl-3 md:pl-5 space-y-1">
              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Provide and Improve Services
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We use the collected information to deliver and improve our
                  App and Services, including analyzing usage patterns,
                  identifying user preferences, enhancing features, and
                  optimizing the App's performance
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Communication</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may use your contact information to communicate with you
                  about your account, updates, promotional offers, and other
                  relevant information related to the App and Services.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Payment Processing</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  If you make payments through the App, we use your transaction
                  information to process payments securely, prevent fraud, and
                  comply with legal requirements
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Location-Based Services
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  our location information is used to match you with nearby
                  pickup and delivery services, provide real-time tracking, and
                  improve the accuracy of the service.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Personalization and Marketing
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may use the information collected to provide personalized
                  content, advertisements, and recommendations based on your
                  preferences and interests
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Legal Compliance</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may use your information to comply with applicable laws,
                  regulations, or legal processes, respond to legal requests, or
                  protect our rights, privacy, safety, or property
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Research and Analytics
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may anonymize and aggregate data for research, analysis,
                  and reporting purposes, aiming to improve our App, Services,
                  and user experience.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Customer Support</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  our information may be used to respond to your inquiries,
                  provide technical support, and address any issues or
                  complaints related to the App and Services
                </p>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
              3. SHARING YOUR INFORMATION
            </h3>

            <ul className="wow fadeInUp mb-5 pl-3 md:pl-5 space-y-1">
              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Customer Support</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  our information may be used to respond to your inquiries,
                  provide technical support, and address any issues or
                  complaints related to the App and Services
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Service Providers</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may share your personal information with trusted
                  third-party service providers who assist us in providing the
                  App and Services, such as payment processors, customer support
                  services, analytics providers, marketing agencies, and hosting
                  providers. These service providers are contractually obligated
                  to protect your data and are not allowed to use your
                  information for any other purposes.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Business Partners</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may share your information with business partners, vendors,
                  or affiliates for joint marketing efforts, promotions, or
                  other business purposes, subject to your consent where
                  required by applicable law
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Legal Compliance and Protection:
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  We may disclose your information to law enforcement or
                  government authorities when required by law or to protect our
                  rights, privacy, safety, or property. We may also share your
                  information in connection with legal proceedings,
                  investigations, or to prevent potential threats
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Business Transfers:</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  In the event of a merger, acquisition, reorganization, or sale
                  of all or a portion of our assets, your information may be
                  transferred to the acquiring entity. In such cases, we will
                  notify you and seek your consent where required by applicable
                  laws.
                </p>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
              4. Data Retention
            </h3>

            <p className="wow fadeInUp mb-5 text-base text-body">
              We will retain your personal information for as long as necessary
              to provide the Services or as required by applicable laws. We may
              retain your data for longer periods if it is necessary to comply
              with legal obligations, resolve disputes, enforce our agreements,
              or for other legitimate business purposes.
            </p>
          </div>

          <div>
            <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
              5. Your Choices
            </h3>

            <ul className="wow fadeInUp mb-5 pl-3 md:pl-5 space-y-1">
              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Account Information:
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  You can access and update your account information within the
                  App. You may also delete your account if you no longer wish to
                  use our Services. Deleting your account will remove your
                  personal information from our active databases, but some
                  residual information may remain in our backups and archives
                  for a limited period.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Location Services:</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  You can disable location services through your device
                  settings, but this may affect the functionality of the App.
                  Please note that disabling location services may prevent you
                  from using certain features or accessing certain Services.
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Marketing Communication:
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  You can opt out of receiving marketing communications by
                  following the instructions provided in the emails or by
                  contacting us directly. However, please note that we may still
                  send you non-promotional communications, such as transactional
                  or service-related messages
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">Cookie Management:</h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  You can manage cookies through your browser settings. Most
                  browsers accept cookies by default, but you can typically
                  modify your settings to reject cookies or notify you when
                  cookies are being sent
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  Do Not Track Signals:
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body">
                  Some web browsers and devices may transmit "Do Not Track"
                  signals. As there is currently no industry standard for
                  responding to these signals, we do not recognize or respond to
                  them at this time.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
            6. Security
          </h3>

          <p className="wow fadeInUp mb-5 text-base text-body">
            We implement reasonable security measures to protect your personal
            information from unauthorized access, alteration, disclosure, or
            destruction. Our security practices are in line with industry
            standards. However, no data transmission or storage system is
            entirely secure. Please take appropriate measures to protect your
            account credentials and information.
          </p>
        </div>

        <div>
          <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
            7. CHILDREN'S PRIVACY:
          </h3>

          <p className="wow fadeInUp mb-5 text-base text-body">
            The Inmotion Delivery App and Services are not intended for use by
            individuals under the age of 18. We do not knowingly collect
            personal information from individuals under 18. If you are a parent
            or guardian and believe your child has provided personal information
            without your consent, please contact us at&nbsp;
            <strong>{EMAILS.at(0)}</strong>. and we will promptly delete the
            information.
          </p>
        </div>

        <div>
          <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
            8. International Data Transfers
          </h3>

          <p className="wow fadeInUp mb-5 text-base text-body">
            Your personal information may be transferred and processed in
            countries other than Kenya. We take appropriate measures to
            safeguard cross-border data transfers, including entering into
            standard contractual clauses where required
          </p>
        </div>

        <div>
          <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
            9. YOUR RIGHTS UNDER KENYAN LAW
          </h3>

          <p className="wow fadeInUp mb-5 text-base text-body">
            As a resident of Kenya, you may have certain rights regarding your
            personal information under the Data Protection Act or any other
            relevant laws. These rights may include the right to access,
            rectify, restrict processing, object to processing, and erasure of
            your personal data. To exercise your rights, please contact us
            at&nbsp;
            <strong>{EMAILS.at(0)}</strong>. We will respond to your request
            within the timeframes required by Kenyan law
          </p>
        </div>

        <div>
          <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
            10. CHANGES TO THIS PRIVACY POLICY
          </h3>

          <p className="wow fadeInUp mb-5 text-base text-body">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for legal, operational, or regulatory
            reasons. We will notify you of any material changes through the App
            or other means. Please review the Privacy Policy periodically for
            updates.
          </p>
        </div>

        <div>
          <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
            11. CONTACT INFORMATION
          </h3>

          <p className="wow fadeInUp mb-5 text-base text-body">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our privacy practices, please contact us at &nbsp;
            <strong>{EMAILS?.at(0)}</strong>.
          </p>
        </div>

        <p className="wow fadeInUp mb-5 text-base text-body">
          By using the Inmotion Delivery App and Services in Kenya, you
          acknowledge that you have read and understood this Privacy Policy and
          agree to the collection, use, and sharing of your information as
          described herein, subject to Kenyan law
        </p>
      </div>
    </InmotionLayout>
  );
};

export default PrivacyPolicy;
