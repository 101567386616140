import React, { useContext } from "react";
import PackageCard from "./PackageCard";
import { Context } from "../../context/StorageContext";

const FloatingCards = () => {
  const { trackingData } = useContext(Context);
  return (
    <div
      className={
        "absolute bottom-5 left-5 hidden md:flex bg-transparent space-x-4 font-inter"
      }
    >

      {/*    package card    */}
      {(trackingData?.status === "on-transit" ||
        trackingData?.status === "warehouse-transit") && (
        <PackageCard number={trackingData?.packageCount} />
      )}
    </div>
  );
};

export default FloatingCards;
