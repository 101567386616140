import { useQuery } from "react-query";
import FeedbackServices from "../services/FeedbackServices";
import { toast } from "react-toastify";
import useFetchPackage from "./useFetchPackage";

const usePackageRating = () => {
  const { pkg } = useFetchPackage();
  const { data: rating } = useQuery({
    enabled: !!pkg,
    queryFn: async () => {
      const data = await FeedbackServices.fetchPackageFeedback(
        pkg?.id,
        pkg?.type
      );
      return data?.data;
    },
    queryKey: ["rating"],
    onError: (err) => {
      toast.error(
        "Rating fetch error. " + err?.response?.data?.message ?? err?.message
      );
    },
  });

  return { rating };
};
export default usePackageRating;
