import React, { useContext } from "react";
import DetailItem from "./DetailItem";
import { COLORS } from "../../../assets/Theme";
import CloseSvg from "../../../assets/svg/CloseSvg";
import PersonSvg from "../../../assets/svg/PersonSvg";
import MobileSvg from "../../../assets/svg/MobileSvg";
import BuildingSvg from "../../../assets/svg/BuildingSvg";
import ReceiptSvg from "../../../assets/svg/ReceiptSvg";
import CollectMyCash from "../../../assets/svg/CollectMyCash";
import { MapPinSvg } from "../../../assets/svg";
import MoneySvg from "../../../assets/svg/MoneySvg";
import { Context } from "../../../context/StorageContext";
import ShiftSvg from "../../../assets/svg/ShiftSvg";

const ReceiptDetails = ({ isOpen, handleClose, disabled = false }) => {
  const { packageDetails } = useContext(Context);

  return (
    <>
      <div
        className={`absolute inset-0 bg-gray-950 opacity-50 z-20 duration-300 ease-in-out transition-all ${
          isOpen ? "translate-x-0" : "translate-x-[250%]"
        } md:hidden`}
      />
      <div
        className={`absolute right-0 md:right-[370px] top-16 md:top-5 bottom-0 md:bottom-5 bg-gray-50 w-full md:w-[330px] p-3 z-40 rounded-3xl font-inter flex flex-col overflow-y-scroll scrollbar-hide duration-300 ease-in-out transition-all
      ${isOpen ? "translate-x-0" : "translate-x-[250%]"}
      `}
      >
        {/*  header   */}
        <div className={"flex justify-between"}>
          <div className={"flex gap-x-2 items-center"}>
            <div className={"h-4 rounded-full bg-primary_300 w-0.5"} />
            <h6 className={"font-bold text-base"}>Sender Details</h6>
          </div>

          <button onClick={handleClose}>
            <CloseSvg h={"17"} color={COLORS.primary} />
          </button>
        </div>

        {/*    sender details  */}
        <div
          className={"my-3 p-2 py-4 bg-white rounded-lg flex flex-col gap-y-4"}
        >
          <DetailItem
            icon={<PersonSvg />}
            title={"Name"}
            desc={packageDetails?.businesses?.name}
          />
          <DetailItem
            icon={<BuildingSvg />}
            title={"Sender Agent"}
            desc={
              packageDetails
                ?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details
                ?.business_name ?? packageDetails?.agents_details?.business_name
            }
          />
          <DetailItem
            icon={<ReceiptSvg />}
            title={"Receipt Number"}
            desc={packageDetails?.receipt_no}
          />
          {packageDetails?.payment_status === "collection" && (
            <DetailItem
              icon={<CollectMyCash />}
              title={"Collect My Cash"}
              desc={"50"}
            />
          )}
          <DetailItem
            icon={<MapPinSvg h={"18"} w={"17"} color={COLORS.grey_500} />}
            title={"Location"}
            desc={
              packageDetails
                ?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details
                ?.agent_locations?.name ??
              packageDetails?.agents_details?.agent_locations?.name
            }
          />
          <DetailItem
            icon={<ShiftSvg h={"18"} w={"17"} color={COLORS.grey_500} />}
            title={"Shift"}
            desc={packageDetails?.shift ?? "__"}
          />
        </div>

        {/* Receiver Details */}
        <div className={"flex gap-x-2 items-center"}>
          <div className={"h-4 rounded-full bg-primary_300 w-0.5"} />
          <h6 className={"font-bold text-base"}>Receiver Details(Customer)</h6>
        </div>

        <div
          className={"my-3 p-2 py-4 bg-white rounded-lg flex flex-col gap-y-4"}
        >
          <DetailItem
            icon={<PersonSvg />}
            title={"Name"}
            desc={packageDetails?.customerName}
          />
          <DetailItem
            icon={<MobileSvg />}
            title={"Phone Number"}
            desc={packageDetails?.customerPhoneNumber}
          />
          <DetailItem
            icon={<BuildingSvg />}
            title={
              packageDetails?.type === "courier" ? "Courier" : "Pickup Point"
            }
            desc={
              packageDetails?.type === "courier"
                ? packageDetails?.couriers?.name
                : "Reception"
            }
          />
          <DetailItem
            icon={<MoneySvg />}
            title={"Payment Status"}
            desc={getPayment(packageDetails?.payment_option)}
          />
          <DetailItem
            icon={<MapPinSvg h={"18"} w={"17"} color={COLORS.grey_500} />}
            title={"Location"}
            desc={
              packageDetails
                ?.agents_details_agent_agent_sent_packages_receieverAgentID_idToagents_details
                ?.agent_locations?.name ??
              packageDetails?.destination?.name ??
              packageDetails?.destination
            }
          />
        </div>
      </div>
    </>
  );
};

export default ReceiptDetails;

const getPayment = (payment) => {
  switch (payment) {
    case "vendor":
      return "Vendor pays delivery";
    case "customer":
      return "Vendor pays delivery";
    case "collection":
      return "Customer pays balance";
    default:
      return "";
  }
};
