import { useState } from "react";
import CloseSvg from "../../assets/svg/CloseSvg";

const Banner = ({ message, className, showClose = false }) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = () => {
    setOpen(!open);
  };

  if (!open) return null;

  return (
    <div className={`w-full bg-primary rounded-md p-3`}>
      <button
        className="ml-auto flex items-center justify-center"
        onClick={toggleOpen}
      >
        <CloseSvg />
      </button>
      <p className="text-sm text-left"> {message} </p>
    </div>
  );
};
export default Banner;
