import { toast } from "react-toastify";

const useError = () => {
  const handleError = ({ error, type = "success", position = "bottom" }) =>
    toast.success(error?.response?.data?.message || error?.message, {
      position,
      type: type,
    });

  return handleError;
};

export default useError;
