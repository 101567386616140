import React from "react";
import InmotionLayout from "../../layout/inmotion";
import { INMOTION_PAYBILL } from "../../constants/Payments";

const Terms = () => {
  return (
    <InmotionLayout className={"center"}>
      <div className="lg:max-w-[1200px] bg-white min-h-screen w-full pt-8 px-8 md:pt-16 md:px-16 ">
        <h2
          className={
            "text-[22px] md:text-3xl font-[700] text-center underline  text-inmotion_primary"
          }
        >
          In Motion Delivery Terms & Conditions
        </h2>

        <div className="text-left mt-3">
          <div>
            <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
              Our Offices, operational hours and locations
            </h3>

            <ul className="wow fadeInUp mb-5 pl-3 md:pl-5 space-y-1">
              <li className="text-base text-body">
                <h5 className="font-semibold text-base">
                  1. Mombasa CBD Branch
                </h5>

                <p className="wow fadeInUp mb-5 text-base text-body pl-2">
                  We are along Haile Sellasie road opposite Camels Joint
                  Restaurant
                  <br />
                  <br />
                  <strong> Working hours: </strong>
                  <br />
                  Monday to Saturday: 8 a.m. to 9 p.m. <br /> Sunday from 10 am
                  to 10 pm
                </p>
              </li>

              <li className="text-base text-body">
                <h5 className="font-semibold text-base">2. Nyali Branch</h5>

                <p className="wow fadeInUp mb-5 text-base text-body pl-2">
                  We are located at Pontoon Suites stores along Links Road on
                  your way to Mamba round about right after Nyali Golf View
                  residence that is opposite Hass Petroleum
                  <br />
                  <br />
                  <strong> Working hours: </strong>
                  <br />
                  Monday to Saturday 8 a.m. to 8 p.m. <br />
                  Sunday: closed.
                </p>
              </li>
            </ul>
          </div>

          <h3 className="font-lato text-base md:text-xl font-[700] mb-2 my-2">
            Items we do not deliver
          </h3>
          <ul className="wow fadeInUp mb-9 list-inside list-disc space-y-4">
            <li className="text-base text-body">Money</li>
            <li className="text-base text-body">food, flowers and groceries</li>
            <li className="text-base text-body">
              explosives, ammunitions and flammable items
            </li>
            <li className="text-base text-body">
              pets, ashes and human remains
            </li>
            <li className="text-base text-body">
              Some fragile products such as gift boxes, some glass or breakable
              items
            </li>
            <li className="text-base text-body">
              Bulky and/or heavy packages suitcases or big boxed items, big
              pillows etc
            </li>
          </ul>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Note
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Cosmetics products made of glass packaging should have bubble wrap
            or any for of protective layers. We reserve the right to refuse
            delivery if the package is among the categories of items we do not
            deliver.
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Packaging guidelines
          </h3>
          <ul className="wow fadeInUp mb-9 list-inside list-disc space-y-4">
            <li className="text-base text-body">
              Package should be properly sealed
            </li>
            <li className="text-base text-body">
              Package should be properly and visibly labeled.
            </li>
          </ul>
          <h3 className="font-lato text-base md:text-md font-[700] mb-2">
            How to label
          </h3>
          <ul className="wow fadeInUp mb-9 list-inside list-disc space-y-4">
            <li className="text-base text-body">Sender Name</li>
            <li className="text-base text-body">Sender Phone no</li>
            <li className="text-base text-body">Office package was dropped</li>
            <li className="text-base text-body">Date it was dropped</li>
            <li className="text-base text-body">Receiver Name</li>
            <li className="text-base text-body">Receiver Phone</li>
            <li className="text-base text-body">
              Location of delivery delivery
            </li>
          </ul>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Note
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            We reserve the right to refuse delivery if the package doesn’t meet
            our packaging and labelling guidelines
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Agent delivery policies
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            We offer same day delivery to our agents within Mombasa from CBD
            through 3 delivery schedules 9:30am,1:30pm and 4pm. Packages dropped
            after 4pm delivery is the following day
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Delivery to our out of county Agents is next day for packages
            dropped before 5pm at any of our cbd offices or 2pm at any of Mtaani
            agents. Packages dropped after this times delivery takes an extra
            day.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Sending from mtaani we offer same day delivery to our agents within
            Mombasa for packages dropped before 2pm at the mtaani agent. Kindly
            follow the process below
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Process of sending a package from mtaani
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Deliveries are to be captured in the PICKUPMTAANI.COM APP generate a
            digital receipt. The code on the receipt is a tracking code senders
            and receivers will use to track deliveries.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Package and label correctly with the sender name, number and
            location sending from, receiver name and number and location of
            delivery.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Notify us via INSTAGRAM DM or WhatsApp by sharing either the receipt
            or package image.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            All payments are made through the APP (Inmotion Delivery)
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Package collection period and Return policy
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            All packages sent to Pickup Points outside Mombasa CBD will stay at
            the collection point for 3 days.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Agent closure within collection period stipulated does not pass
            liability of any kind on Inmotion Delivery. Customer finding an
            agent closed are advised to visit again within the 3days period. We
            are however liable to home delivery if the client has visited the
            location and notified us of agent closure for the 3 collection days.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Inmotion Delivery will only assume responsibility of delivering via
            home delivery if the agent remains closed for a continued period of
            3days from the date the customer received their collection
            notification.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            After 3 days packages are returned to the office in CBD and senders
            notified via SMS. Inmotion Delivery does not guarantee that the
            return message will be delivered therefore urge customers to claim
            any unfulfilled deliveries within 10days from the date
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            The senders will have 7 days to collect the returned packages. All
            uncollected returns will be disposed off after 30days.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Packages that have exceeded 10days from the date of dispatch will
            accrue a daily fee of ksh.100, the fine is to be settled before
            collection of the package.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Inmotion Delivery will not be held liable for any uncollected return
            package.
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Claim policy
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            No action against the company in respect of any claims arising out
            if carriage of goods shall be made tangible unless written notice of
            such claim is given to the company within a period of 10days from
            the date of delivery.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Pick-up mtaani shall not be held liable for any packages that is
            claimed after 10 days of being sent.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            In any event the amount payable for the one claim for loss or damage
            shall not excced kshs. 2000.
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Damaged/missing delivery refund procedure and policy
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            If you receive your package damaged you are required to report to
            our customer service team via DM, Whatsapp or Call while still at
            the Pickup point/agent location. Share an image of the damaged
            produc
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Tampered packaging is to be reported immediately to our customer
            service team via DM, whatsapp or Call while still at the agent
            during collection. Share an image of how you’ve received the
            package.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Inmotion Delivery refrains from delivery of fragile products,
            delivery is at owners risk. The company shall not be held liable for
            any damage claim.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            To process any refund the following documents are to be presented;
          </p>
          <ul className="wow fadeInUp mb-9 list-inside list-disc space-y-4">
            <li className="text-base text-body">Sender Name</li>
            <li className="text-base text-body">
              Mpesa statement of the purchase transaction from both the seller
              and buyer
            </li>
            <li className="text-base text-body">
              Image representation of the product on the sellers
              Instagram/Facebook page.
            </li>
          </ul>
          <p className="wow fadeInUp mb-10 text-base text-body">
            The company reserves a right to decline any claim if the above
            documents are not presented or present any evidence of alterations.
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            What do I need to collect your package? You may ask
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Receivers are required to present either the notification message
            received from Inmotion Delivery or their national Identification
            card or passport.
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Agent location and operational hours are available on the agent
            profile tab on PICKUPMTAANI.COM .Track the status of your package
            using your package code. (IMD-AGN-IMD-XXXXX)
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            The company may refuse to hand over the consignment if in doubt of
            the identity of the cosignee, cosignee will be required to produce
            sufficient acceptable identity and any acceptable authority if
            necessary.
          </p>
          <h3 className="font-lato text-base md:text-xl font-[700] mb-2">
            Home/Office delivery policies
          </h3>
          <p className="wow fadeInUp mb-10 text-base text-body">
            We offer same day doorstep delivery for packages dropped at our town
            offices before 4pm. Past this time delivery is done the following
            day
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            From mtaani agents, we offer same day delivery for packages dropped
            before 2pm. Past this time delivery is done the following day.
          </p>
          <h3 className="font-lato text-base md:text-2xl font-[700] mb-2">
            Process of sending doorstep delivery from mtaani
          </h3>
          <h2 className="font-lato text-xl my-1 font-[700]">How to label</h2>
          <ul className="wow fadeInUp mb-9 list-inside list-disc space-y-4">
            <li className="text-base text-body">Sender Name</li>
            <li className="text-base text-body">Sender Phone no</li>
            <li className="text-base text-body">Office package was dropped</li>
            <li className="text-base text-body">Date it was dropped</li>
            <li className="text-base text-body">Receiver Name</li>
            <li className="text-base text-body">Receiver Phone</li>
            <li className="text-base text-body">Doorstep delivery location</li>
          </ul>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Notify us via INSTAGRAM DM or WhatsApp by sharing either the receipt
            or package image
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            All delivery payments are made to our Paybill no.{" "}
            <strong>{INMOTION_PAYBILL}</strong> Account Number:{" "}
            <strong>Delivery</strong>
          </p>
          <p className="wow fadeInUp mb-10 text-base text-body">
            Our deliveries are based on 3schedules; delivery is within 2-3hrs
            from dispatch schedule, we therefore do not give specific time of
            delivery we however guarantee same delivery. We currently do not
            facilitate on demand delivery we however look forward to offering
            the services <soon className=""></soon>
          </p>
        </div>
      </div>
    </InmotionLayout>
  );
};

export default Terms;
