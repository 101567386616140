import { PACKAGE_STATES } from "../constants/delivery";

/**
 * @link https://pickupmtaani.atlassian.net/browse/DEV-279
 * @since X.X.X
 *
 * @param {String} state
 * @returns String
 * @desc gets the current stage(agent, warehouse, rider) of a package for tracking purposes
 */
function getCurrentStage(state) {
  switch (state) {
    case "assigned":
      return "agent";

    case "pending-stock-agent":
      return "agent";
    case "pending-shelf-agent":
      return "agent";
    case "pending-stock-doorstep":
      return "agent";
    case "pending-shelf-doorstep":
      return "agent";
    case "pending-stock-errand":
      return "agent";
    case "pending-shelf-errand":
      return "agent";
    case "on-transit":
      return "rider";
    case "dropped":
      return "warehouse";
    case "recieved-warehouse":
      return "warehouse";
    case "assigned-warehouse":
      return "warehouse";
    case "warehouse-transit":
      return "rider";
    case "delivered":
      return "agent";
    case "collected":
      return "agent";
    default:
      break;
  }
}

const WAREHOUSE = {
  id: 3,
  agent_description: "opposite Afya center ",
  business_name: "Philadelphia house",
  agent_locations: {
    id: 168,
    name: "CBD - TOWN NAIROBI",
  },
};
const getCurrentAgent = (state, pkg) => {
  switch (state) {
    case "assigned":
      return (
        pkg?.package
          ?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details ||
        pkg?.package?.agents_details
      );
    case "on-transit":
      return null;
    case "dropped":
      return WAREHOUSE;
    case "recieved-warehouse":
      return WAREHOUSE;
    case "assigned-warehouse":
      return WAREHOUSE;
    case "warehouse-transit":
      return null;
    case "delivered":
      if (pkg?.package?.type === "agent") {
        return pkg?.package
          ?.agents_details_agent_agent_sent_packages_receieverAgentID_idToagents_details;
      }
      return null;
    case "collected":
      return "agent";
    default:
      break;
  }
};

function getCurrentAgentStateDetails(state, rsn, pkg) {
  switch (state) {
    case PACKAGE_STATES.rejected:
      return {
        stage: 1,
        title: "Package Rejected",
        desc: `Package from ${pkg?.businesses?.name} Rejected due to, ${
          rsn || ""
        }`,
      };
    case PACKAGE_STATES.pickedFromSeller:
    case PACKAGE_STATES.earlyCollection:
    case PACKAGE_STATES.booked:
      return {
        stage: 0,
        title: "Awaiting Collection",
        desc: `Package from ${pkg?.businesses?.name} is awaiting pickup by customer.`,
      };
    case PACKAGE_STATES.assigned:
      return {
        stage: 1,
        title: "Dropped to Agent",
        desc: `Package from ${pkg?.businesses?.name} dropped to ${pkg?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details?.business_name} at ${pkg?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details?.agent_locations?.name} and is awaiting collection by rider for delivery.`,
      };
    case PACKAGE_STATES.created:
      return {
        stage: 0,
        title: "Package Submitted",
        desc: `Package from ${pkg?.businesses?.name} Submitted and is awaiting drop-off to ${pkg?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details?.business_name} at ${pkg?.agents_details_agent_agent_sent_packages_senderAgentID_idToagents_details?.agent_locations?.name}.`,
      };
    case PACKAGE_STATES.pendingStockAgent:
    case PACKAGE_STATES.pendingShelfAgent:
    case PACKAGE_STATES.pendingStockDoorstep:
    case PACKAGE_STATES.pendingShelfDoorstep:
    case PACKAGE_STATES.pendingStockErrand:
    case PACKAGE_STATES.pendingShelfErrand:
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: `Package from ${pkg?.businesses?.name} is Awaiting approval by shelf provider.`,
      };
    case PACKAGE_STATES.onTransit:
      return {
        stage: 2,
        title: "On The Way",
        desc: `Package from ${pkg?.businesses?.name} is being delivered to warehouse for sorting`,
      };
    case PACKAGE_STATES.dropped:
    case PACKAGE_STATES.receivedWarehouse:
    case PACKAGE_STATES.assignedWarehouse:
      return {
        stage: 3,
        title: "Sorting",
        desc: `Package from ${pkg?.businesses?.name} is at warehouse for sorting`,
      };
    case PACKAGE_STATES.warehouseTransit:
      return {
        stage: 4,
        title: "On The Way",
        desc: `Package from ${pkg?.businesses?.name} is being delivered to destination.`,
      };
    case PACKAGE_STATES.delivered:
      return {
        stage: 5,
        title: "Delivered",
        desc: `Package from ${pkg?.businesses?.name} has been delivered to ${pkg?.agents_details_agent_agent_sent_packages_receieverAgentID_idToagents_details?.business_name} at ${pkg?.agents_details_agent_agent_sent_packages_receieverAgentID_idToagents_details?.agent_locations?.name} and is awaiting collection. Use release code ${pkg?.collection_otp} during collection.`,
      };
    case PACKAGE_STATES.collected:
      return {
        stage: 6,
        title: "Collected",
        desc: `Package from ${pkg?.businesses?.name} collected by customer`,
      };

    case PACKAGE_STATES.returnRequest:
      return {
        stage: 1,
        title: "Return Initiated",
        desc: "Package Return initiated.",
      };
    case PACKAGE_STATES.returnTransit:
      return {
        stage: 3,
        title: "Package in Transit",
        desc: "Package is currently on its way to the collection point.",
      };
    case PACKAGE_STATES.returnDropped:
    case PACKAGE_STATES.returnReceived:
    case PACKAGE_STATES.returnAssigned:
      return {
        stage: 2,
        title: "Sorting",
        desc: "Package is at warehouse for sorting",
      };
    case PACKAGE_STATES.returnDelivered:
      return {
        stage: 4,
        title: "Delivered",
        desc: "Package has been delivered to destination and is awaiting collection.",
      };
    case PACKAGE_STATES.returnCollected:
      return {
        stage: 6,
        title: "Delivered",
        desc: "Package collected.",
      };
    default:
      break;
  }
}
function getCurrentDoorstepStateDetails(state, rsn, pkg) {
  switch (state) {
    case PACKAGE_STATES.rejected:
      return {
        stage: 1,
        title: "Package Rejected",
        desc: `Package from ${pkg?.businesses?.name} Rejected due to, ${
          rsn || ""
        }`,
      };
    case PACKAGE_STATES.pickedFromSeller:
    case PACKAGE_STATES.earlyCollection:
    case PACKAGE_STATES.booked:
      return {
        stage: 0,
        title: "Awaiting Collection",
        desc: `Package from ${pkg?.businesses?.name} is awaiting pickup by customer.`,
      };
    case PACKAGE_STATES.assigned:
      return {
        stage: 1,
        title: "Dropped to Agent",
        desc: `Package from ${pkg?.businesses?.name} dropped to ${pkg?.agents_details?.business_name} at ${pkg?.agents_details?.agent_locations?.name} and is awaiting collection by rider for delivery.`,
      };
    case PACKAGE_STATES.created:
      return {
        stage: 0,
        title: "Package Submitted",
        desc: `Package from ${pkg?.businesses?.name} Submitted and is awaiting drop-off to ${pkg?.agents_details?.business_name} at ${pkg?.agents_details?.agent_locations?.name}.`,
      };
    case PACKAGE_STATES.pendingStockAgent:
    case PACKAGE_STATES.pendingShelfAgent:
    case PACKAGE_STATES.pendingStockDoorstep:
    case PACKAGE_STATES.pendingShelfDoorstep:
    case PACKAGE_STATES.pendingStockErrand:
    case PACKAGE_STATES.pendingShelfErrand:
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: `Package from ${pkg?.businesses?.name} is Awaiting approval by shelf provider.`,
      };
    case PACKAGE_STATES.onTransit:
      return {
        stage: 2,
        title: "On The Way",
        desc: `Package from ${pkg?.businesses?.name} is being delivered to warehouse for sorting`,
      };
    case PACKAGE_STATES.dropped:
    case PACKAGE_STATES.receivedWarehouse:
    case PACKAGE_STATES.assignedWarehouse:
      return {
        stage: 3,
        title: "Sorting",
        desc: `Package from ${pkg?.businesses?.name} is at warehouse for sorting`,
      };
    case PACKAGE_STATES.warehouseTransit:
      return {
        stage: 4,
        title: "On The Way",
        desc: `Package from ${pkg?.businesses?.name} is being delivered to destination.`,
      };
    case PACKAGE_STATES.delivered:
      return {
        stage: 5,
        title: "Delivered",
        desc: `Package from ${pkg?.businesses?.name} has been delivered to destination.`,
      };
    case PACKAGE_STATES.collected:
      return {
        stage: 6,
        title: "Collected",
        desc: `Package from ${pkg?.businesses?.name} collected by customer`,
      };

    case PACKAGE_STATES.returnRequest:
      return {
        stage: 1,
        title: "Return Initiated",
        desc: "Package Return initiated.",
      };
    case PACKAGE_STATES.returnTransit:
      return {
        stage: 3,
        title: "Package in Transit",
        desc: "Package is currently on its way to the collection point.",
      };
    case PACKAGE_STATES.returnDropped:
    case PACKAGE_STATES.returnReceived:
    case PACKAGE_STATES.returnAssigned:
      return {
        stage: 2,
        title: "Sorting",
        desc: "Package is at warehouse for sorting",
      };
    case PACKAGE_STATES.returnDelivered:
      return {
        stage: 4,
        title: "Delivered",
        desc: "Package has been delivered to destination and is awaiting collection.",
      };
    case PACKAGE_STATES.returnCollected:
      return {
        stage: 6,
        title: "Delivered",
        desc: "Package collected.",
      };
    default:
      break;
  }
}

function getCurrentStateDetails(state, rsn, pkg) {
  if (pkg?.type === "agent") {
    return getCurrentAgentStateDetails(state, rsn, pkg);
  }
  if (pkg?.type === "doorstep") {
    return getCurrentDoorstepStateDetails(state, rsn, pkg);
  }
  switch (state) {
    case PACKAGE_STATES.rejected:
      return {
        stage: 1,
        title: "Package Rejected",
        desc: `Package Rejected due to, ${rsn || ""}`,
      };
    case PACKAGE_STATES.pickedFromSeller:
    case PACKAGE_STATES.earlyCollection:
    case PACKAGE_STATES.booked:
      return {
        stage: 0,
        title: "Awaiting Collection",
        desc: "Package is awaiting pickup by customer.",
      };
    case PACKAGE_STATES.assigned:
      return {
        stage: 1,
        title: "Dropped to Agent",
        desc: "Package dropped to sender agent and is awaiting collection by rider for delivery.",
      };
    case PACKAGE_STATES.request:
      return {
        stage: 0,
        title: "Package Submitted",
        desc: "Package Submitted and is awaiting drop-off to agent.",
      };
    case PACKAGE_STATES.pendingStockAgent:
    case PACKAGE_STATES.pendingShelfAgent:
    case PACKAGE_STATES.pendingStockDoorstep:
    case PACKAGE_STATES.pendingShelfDoorstep:
    case PACKAGE_STATES.pendingStockErrand:
    case PACKAGE_STATES.pendingShelfErrand:
      return {
        stage: 1,
        title: "Awaiting approval",
        desc: "Package is Awaiting approval by shelf provider.",
      };
    case PACKAGE_STATES.onTransit:
      return {
        stage: 2,
        title: "On The Way",
        desc: "Package is being delivered to warehouse for sorting",
      };
    case PACKAGE_STATES.dropped:
    case PACKAGE_STATES.receivedWarehouse:
    case PACKAGE_STATES.assignedWarehouse:
      return {
        stage: 3,
        title: "Sorting",
        desc: "Package is at warehouse for sorting",
      };
    case PACKAGE_STATES.warehouseTransit:
      return {
        stage: 4,
        title: "On The Way",
        desc: "Package is being delivered to destination.",
      };
    case PACKAGE_STATES.delivered:
      return {
        stage: 5,
        title: "Delivered",
        desc: "Package has been delivered to destination and is awaiting collection.",
      };
    case PACKAGE_STATES.collected:
      return {
        stage: 6,
        title: "Collected",
        desc: "Package collected by customer",
      };

    case PACKAGE_STATES.returnRequest:
      return {
        stage: 1,
        title: "Return Initiated",
        desc: "Package Return initiated.",
      };
    case PACKAGE_STATES.returnTransit:
      return {
        stage: 3,
        title: "Package in Transit",
        desc: "Package is currently on its way to the collection point.",
      };
    case PACKAGE_STATES.returnDropped:
    case PACKAGE_STATES.returnReceived:
    case PACKAGE_STATES.returnAssigned:
      return {
        stage: 2,
        title: "Sorting",
        desc: "Package is at warehouse for sorting",
      };
    case PACKAGE_STATES.returnDelivered:
      return {
        stage: 4,
        title: "Delivered",
        desc: "Package has been delivered to destination and is awaiting collection.",
      };
    case PACKAGE_STATES.returnCollected:
      return {
        stage: 6,
        title: "Delivered",
        desc: "Package collected.",
      };
    default:
      break;
  }
}

/**
 * checks if the page required(prev/next) is available
 * @param {number} pageLimit pagination limit
 * @param {number} totalNumberOfItems total number of locations count
 * @param {string} pageNumber current page
 * @param {string} pageRequired page check needed i.e. next/prev
 */
function getNextPrevPage(
  pageLimit,
  totalNumberOfItems,
  pageNumber,
  pageRequired
) {
  const currentPage = parseInt(pageNumber, 10);
  // calculate total number of pages available
  const totalPagesAvailable = Math.ceil(totalNumberOfItems / pageLimit);

  if (pageRequired === "next") {
    // check if current page is not equal to total pages available
    if (currentPage < totalPagesAvailable && currentPage >= 0) {
      return currentPage + 1;
    } else {
      if (currentPage === totalPagesAvailable) {
        return currentPage;
      }
      return;
    }
  }

  if (pageRequired === "prev") {
    // ensure current page is not 1
    if (currentPage !== 0) {
      return currentPage - 1;
    } else {
      return currentPage;
    }
  }

  return null;
}

function numberToK(num) {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  }
  return num;
}

const Helper = {
  getCurrentStage,
  getCurrentAgent,
  getCurrentStateDetails,
  getNextPrevPage,
  numberToK,
};

export default Helper;
