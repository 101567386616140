import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog, DialogContent, DialogTrigger } from "../general/Dialog";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ControlledTextArea } from "../form/Input";
import { SubmitButton } from "../form/Button";
import { ControlledRating } from "../form/RatingInputField";
import { useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import TrackingServices from "../../services/TrackingServices";
import { Context } from "../../context/StorageContext";
import { useLocation } from "react-router-dom";
import FeedbackServices from "../../services/FeedbackServices";
import usePackageRating from "../../hooks/usePackageRating";

const formSchema = z.object({
  rate: z.number().min(1, "Rating is required"),
  feedback: z.string().min(3, "Minimum of 3 characters required"),
});

const FeedbackDialog = ({ children }) => {
  const { trackingData, setPackageDetails } = useContext(Context);
  const { rating } = usePackageRating();
  const queryClient = useQueryClient();
  const path = useLocation();
  const { data } = useQuery({
    queryKey: ["package", trackingData],
    queryFn: () =>
      TrackingServices.fetchPackage(
        trackingData?.id,
        trackingData?.category === "errand"
          ? "courier"
          : trackingData?.category,
        path?.pathname
      ),
    onSuccess: (res) => {
      setPackageDetails(res?.package);
    },
    onError: (err) => {
      toast.error(
        "Package fetch error. " + err?.response?.data?.message ?? err?.message
      );
    },
    enabled: !!trackingData?.id,
  });
  const pkg = useMemo(() => data?.package, [data?.package]);

  //package can only be rated within 14 days after last being updated
  const canRate = useMemo(() => {
    const now = new Date();
    const updatedAt = new Date(pkg?.updatedAt);
    return Math.abs((now - updatedAt) / (1000 * 60 * 60 * 24)) < 14;
  }, [data?.package]);
  const [open, setOpen] = useState(false);
  const { control, watch, handleSubmit, reset } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      rating: 0,
      feedback: "",
    },
  });

  const postFeedback = async () => {
    try {
      const data = watch();
      data.packageId = pkg?.id;
      data.packageType = pkg?.type;
      console.log({ data });
      await FeedbackServices.createPackageFeedback(data);
      await queryClient.invalidateQueries("rating");
      reset();
      toast.success(`Feedback sent.`, {
        position: "bottom-center",
        autoClose: 2000,
      });
      setOpen(false);
    } catch (e) {
      console.log({ e });
      toast.error(
        `There was an error: ${e?.response?.data?.message || e?.message}`,
        { position: "bottom-center", autoClose: 2000 }
      );
      throw e;
    }
  };
  const postMutation = useMutation({
    mutationFn: postFeedback,
  });

  const onSubmit = async (e) => {
    e?.preventDefault();
    console.log("submitting");
    await postMutation.mutate();
  };

  if (!canRate) {
    return null;
  }

  return (
    <Dialog open={open}>
      {/*trigger*/}
      <DialogTrigger asChild onClick={() => setOpen(!open)}>
        {!rating && children}
      </DialogTrigger>

      {/*form*/}
      <DialogContent
        className="bg-white pt-10"
        toggleOpen={() => setOpen(!open)}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(onSubmit(e));
          }}
        >
          <h5 className="border-l-2 pl-2 font-bold mb-2 border-primary">
            Feedback
          </h5>

          {/* rating */}
          <ControlledRating
            control={control}
            name={"rating"}
            containerStyles={"mb-2"}
          />

          {/* Feedback */}
          <ControlledTextArea
            label={"Enter your feedback"}
            placeholder={"...enter your feedback here"}
            name={"feedback"}
            control={control}
            required
          />

          <SubmitButton
            disabled={postMutation.isLoading}
            type="submit"
            className={`outline-none${
              postMutation.isLoading ? "opacity-60" : "opacity-100"
            }`}
          >
            {postMutation.isLoading ? "sending feedback..." : "send feedback"}{" "}
          </SubmitButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default FeedbackDialog;
