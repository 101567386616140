import React, { useState } from "react";
import ContactServices from "../../services/ContactServices";
import { toast } from "react-toastify";
import { InputItem } from "../../components/dashboard/Contact";
import Loader from "../../components/general/Loader";
import InmotionLayout from "../../layout/inmotion";
import { EMAILS, PHONE_NUMS } from "../../constants/contacts";

const Contact = () => {
  const [state, setState] = useState({
    first: "",
    last: "",
    phone: "",
    email: "",
    message: "",
    verified: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  async function sendMessage(e) {
    e.preventDefault();

    setLoading(true);

    try {
      await ContactServices.sendEmail({
        name: state.first + " " + state.last,
        phone: state.phone,
        email: state.email,
        message: state.message,
      });
      setLoading(false);
      toast.success(`Email sent.`, {
        position: "bottom-center",
      });
      setState({
        first: "",
        last: "",
        phone: "",
        email: "",
        message: "",
        verified: false,
      });
    } catch (e) {
      toast.error(`Email not sent. ${e.response.data?.message || e.message}`, {
        position: "bottom-center",
      });

      setLoading(false);
    }
  }

  return (
    <InmotionLayout>
      <div
        className={
          "lg:max-w-[1200px] mx-auto bg-white px-4 md:p-5 md:pb-48 min-h-screen center"
        }
      >
        <form className={"lg:mx-20 w-full"} onSubmit={sendMessage}>
          <h1
            className={
              "font-times text-[32px]  md:text-[48px] text-center font-[600] md:text-center text-inmotion_primary leading-[52px] "
            }
          >
            Contact Us
          </h1>

          <div
            className={
              "flex flex-col md:flex-row py-3 md:py-8 md:gap-x-7 pr-2 items-start "
            }
          >
            <div className={"flex flex-col w-full gap-y-4 md:w-1/2 pt-1 "}>
              <div
                className={
                  "flex flex-col md:flex-row md:gap-x-4 gap-y-4 md:gap-y-0"
                }
              >
                <InputItem
                  required
                  label={"First name"}
                  placeHolder={"First name"}
                  name={"first"}
                  value={state?.first}
                  onChange={handleChange}
                />
                <InputItem
                  label={"Last name"}
                  name={"last"}
                  value={state?.last}
                  onChange={handleChange}
                  placeHolder={"Last name"}
                />
              </div>

              <InputItem
                required
                label={"Email"}
                placeHolder={"you@company.com"}
                type={"email"}
                name={"email"}
                value={state?.email}
                onChange={handleChange}
              />

              <InputItem
                required
                label={"Phone number"}
                placeHolder={"+254 712 345678"}
                name={"phone"}
                value={state?.phone}
                onChange={handleChange}
              />

              <div className={"hidden md:block"}>
                <h6
                  className={
                    "font-[700] text-base md:text-[21px] font-lato text-black"
                  }
                >
                  Support
                </h6>

                <div
                  className={
                    "flex flex-col sm:flex-row gap-1 md:gap-x-2 mt-2 mb-2 md:mb-0"
                  }
                >
                  <ul className={"flex-1"}>
                    <li>
                      <strong>Phone</strong>
                    </li>
                    <li>+254 743 032 081</li>
                    <li>+254 104 580 014</li>
                    <li>+254 757 771 024</li>
                  </ul>
                  <div className={"flex-1"}>
                    <strong>Email:</strong>
                    <p>citylinkdeliveryltd@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={"flex flex-col gap-y-4 w-full md:w-1/2 mt-2 md:mt-0 "}
            >
              <div className={"text-sm font-inter font-[500] text-grey_700 "}>
                Message
              </div>

              <textarea
                required
                className={
                  "h-56 rounded-lg bg-white p-2 border border-grey_300 w-full"
                }
                name={"message"}
                onChange={handleChange}
                value={state?.message}
              />

              <div
                className={
                  " font-inter font-[400] text-base text-grey_500 flex items-center gap-x-2  "
                }
              >
                <input
                  type={"checkbox"}
                  required
                  className={
                    "h-5 w-5 bg-white border border-grey_300 outline-none rounded overflow-hidden"
                  }
                />

                <p className={"font-inter text-sm md:text-base"}>
                  {" "}
                  Your agree to our friendly{" "}
                  <a
                    href={"/inmotion/terms"}
                    className={"underline cursor-pointer text-black"}
                  >
                    Terms and Conditions
                  </a>
                </p>
              </div>

              <button
                className={`flex-1 bg-inmotion_primary text-white py-3 px-5 text-center font-[600] font-inter rounded-lg ${
                  loading && "opacity-70 cursor-no-drop"
                }`}
                type={"submit"}
                disabled={loading}
              >
                {loading ? <Loader /> : "Send message"}
              </button>
            </div>

            <div className={"md:hidden mt-2 flex flex-col sm:flex-row"}>
              <ul className={"flex-1"}>
                <li>
                  <strong>Phone</strong>
                </li>
                {PHONE_NUMS.map((phone, ind) => (
                  <li key={ind}>+254 {phone}</li>
                ))}
              </ul>

              <div className={"flex-1 mt-2 md:mt-0"}>
                <strong>Email:</strong>
                <br />

                {EMAILS.map((email, ind) => (
                  <p key={ind}> {email}</p>
                ))}
              </div>
            </div>
          </div>
        </form>
      </div>
    </InmotionLayout>
  );
};

export default Contact;
