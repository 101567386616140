import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  InmotionContact,
  InmotionPrivacyPolicy,
  InmotionTerms,
  InmotionWalletReset,
  Tracker
} from "./pages";
import Terms from "./pages/Terms";
import Navbar from "./components/general/Navbar";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Agents from "./pages/Agents";
import Agent from "./pages/Agent";
import SideNav from "./components/general/SideNav";
import WalletReset from "./pages/WalletReset.js";
import { QueryClient, QueryClientProvider } from "react-query";
import StorageContext from "./context/StorageContext";
import RentShelfPolicy from "./pages/PolicyRentShelf.js";
import RiderPrivacyPolicy from "./pages/PolicyRider.js";
import CollectCashPayment from "./pages/CollectCashPayment";
import VendorPrivacyPolicy from "./pages/PolicyVendor";
import ShelfManagerPay from "./pages/ShelfManagerPay";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://61de9bb6398e0c36a576d81952d2ecab@o4507623056408576.ingest.de.sentry.io/4507901259808848",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const [openSideBar, setOpenSideBar] = useState(false);

  const queryClient = new QueryClient();

  return (
    <StorageContext>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <div className="App min-h-[100vh] bg-white_bg overflow-x-hidden">
            <Navbar openSideNav={() => setOpenSideBar(true)} />
            <SideNav
              isOpen={openSideBar}
              handleCloseNav={() => setOpenSideBar(false)}
            />
            <Routes>
              <Route path={"/"} element={<Dashboard />} />
              <Route path={"/track-package"} element={<Tracker />} />
              <Route path={"/terms"} element={<Terms />} />
              <Route path={"/wallet-reset"} element={<WalletReset />} />
              <Route path={"/agents"} element={<Agents />} />
              <Route path={"/agent/:id"} element={<Agent />} />
              <Route
                path={"/privacy-policy/rent-shelf-manager"}
                element={<RentShelfPolicy />}
              />
              <Route
                path={"/privacy-policy/rider"}
                element={<RiderPrivacyPolicy />}
              />
              <Route
                path={"/privacy-policy/vendor"}
                element={<VendorPrivacyPolicy />}
              />
              <Route
                path={"/payment/:type/:receipt"}
                element={<CollectCashPayment />}
              />
              <Route
                path={"/payment-shelf/:shelfid/"}
                element={<ShelfManagerPay />}
              />

              {/* in motion pages */}
              <Route
                path={"/inmotion/wallet-reset"}
                element={<InmotionWalletReset />}
              />
              <Route path={"/inmotion-track-package"} element={<Tracker />} />
              <Route
                path={"/inmotion/privacy"}
                element={<InmotionPrivacyPolicy />}
              />
              <Route path={"/inmotion/terms"} element={<InmotionTerms />} />
              <Route path={"/inmotion/contact"} element={<InmotionContact />} />
            </Routes>
            <ToastContainer />
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    </StorageContext>
  );
}

export default App;
