import { Controller } from "react-hook-form";

export const ControlledLabeledInput = ({
  control,
  name,
  rules,
  containerClasses,
  required = false,
  label,
  inputClasses,
  description,
  placeholder
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <div
        className={`mx-auto mb-4 flex-column items-start align-start ${containerClasses}`}
      >
        <label htmlFor={name} className="text-md block">
          {" "}
          {label} {required && <span className="text-[red]">*</span>}{" "}
        </label>
        {description && <label htmlFor={name} className="opacity-70 text-xs"> {description} </label>}
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`rounded-md border border-primary  p-1 w-full ${inputClasses}`}
        />
        {error?.message ? (
          <p className="text-sm text-[red] mx-auto"> {error?.message} </p>
        ) : null}
      </div>
    )}
  />
);

export const ControlledSelector = ({
  control,
  name,
  rules, containerClasses,
  required = false,
  label,
  inputClasses,
  options,
  optionKey,
  description,
  optionValue,
  placeholder
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}

      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div
          className={`mx-auto mb-4 flex-column items-start align-start ${containerClasses}`}
        >
          <label htmlFor={name} className="text-md block">
            {" "}
            {label} {required && <span className="text-[red]">*</span>}{" "}
          </label>
          {description && <label htmlFor={name} className="opacity-70 text-xs"> {description} </label>}
          <select
            placeholder={placeholder}
            value={value}
            onChange={ onChange}
            className={`rounded-md border border-primary  p-1 w-full ${inputClasses}`}
          >

            {(Array.isArray(options) && options.length < 1) || !options ? (
              <option value={null}>No Options Available</option>
            ) : null}
            {Array.isArray(options) && options.length > 0
              ? options.map((option) => (
                  <option key={option[optionValue]} value={option[optionValue]}>
                    {" "}
                    {option[optionKey]}{" "}
                  </option>
                ))
              : null}
          </select>
          {error?.message ? (
            <p className="text-sm text-[red] mx-auto">
              {" "}
              {error?.message}{" "}
            </p>
          ) : null}
        </div>
      )}
    />
  );
};

export const ControlledTextArea = ({
  control,
  name,
  rules,
  containerClasses,
  required = false,
  label,
  inputClasses,
  description,
  placeholder,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <div
        className={`mx-auto mb-4 flex-column items-start align-start ${containerClasses}`}
      >
        <label htmlFor={name} className="text-md block">
          {" "}
          {label} {required && <span className="text-[red]">*</span>}{" "}
        </label>
        {description && <label htmlFor={name} className="opacity-70 text-xs"> {description} </label>}
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`rounded-md border border-primary  p-1 w-full ${inputClasses}`}
        />
        {error?.message ? (
          <p className="text-sm text-[red] mx-auto"> {error?.message} </p>
        ) : null}
      </div>
    )}
  />
);
