import React from "react";

const InmotionLayout = ({ children, className }) => {
  return (
    <div className={`bg-inmotion_primary min-h-screen ${className}`}>
      {children}
    </div>
  );
};

export default InmotionLayout;
