import { useContext, useMemo } from "react";
import { Context } from "../context/StorageContext";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import TrackingServices from "../services/TrackingServices";
import { toast } from "react-toastify";

const useFetchPackage = () => {
  const { trackingData } = useContext(Context);
  const path = useLocation();
  const { data } = useQuery({
    queryKey: ["package", trackingData],
    queryFn: () =>
      TrackingServices.fetchPackage(
        trackingData?.id,
        trackingData?.category === "errand"
          ? "courier"
          : trackingData?.category,
        path?.pathname
      ),

    onError: (err) => {
      toast.error(
        "Package fetch error. " + err?.response?.data?.message ?? err?.message
      );
    },
    enabled: !!trackingData?.id,
  });

  const pkg = useMemo(() => data?.package, [data?.package]);

  return { pkg };
};

export default useFetchPackage;
