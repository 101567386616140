import React, { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useScrollDirection } from "react-use-scroll-direction";
import { Context } from "../../../context/StorageContext";
import TrackingServices from "../../../services/TrackingServices";
import Banner from "../../general/Banner";
import PackageDetails from "./PackageDetails";
import PackageJourney from "./PackageJourney";
import PackageLocation from "./PackageLocation";
import PackageRating from "./PackageRating";
import RiderDetails from "./RiderDetails";
import SearchInput from "./SearchInput";
import { PacakgeETA } from "./PackageETA";

const OrderDetails = ({ handleReceiptClick, handleViewJourney }) => {
  const { trackingData, setPackageDetails } = useContext(Context);
  const { scrollTargetRef } = useScrollDirection();
  const path = useLocation();

  const { data } = useQuery({
    queryKey: ["package", trackingData],
    queryFn: () =>
      TrackingServices.fetchPackage(
        trackingData?.id,
        trackingData?.category === "errand"
          ? "courier"
          : trackingData?.category,
        path?.pathname
      ),
    onSuccess: (res) => {
      setPackageDetails(res?.package);
    },
    onError: (err) => {
      toast.error(
        "Package fetch error. " + err?.response?.data?.message ?? err?.message
      );
    },
    refetchInterval: 2000,
    enabled: !!trackingData?.id,
  });

  const pkg = useMemo(() => data?.package, [data?.package]);

  return (
    <div
      className={`absolute right-0 md:right-5 md:top-5 bottom-0 md:bottom-5 bg-gray-50 duration-500 h-[65vh] md:h-auto w-full md:w-[330px] p-3 rounded-t-3xl md:rounded-3xl z-30 font-inter flex flex-col gap-y-3 overflow-y-scroll scrollbar-hide pb-5`}
      ref={scrollTargetRef}
    >
      {/*search input*/}
      <SearchInput />

      {/*no cash banner*/}
      <Banner message="Note that we only allow payments through our till number and via stk prompt to Pickup Mtaani" />

      {/*    rider details card     */}
      {(pkg?.state === "on-transit" || pkg?.state === "warehouse-transit") && (
        <RiderDetails
          riderName={
            pkg?.state === "on-transit" ? pkg?.rider1?.name : pkg?.rider2.name
          }
          riderPhone={
            pkg?.state === "on-transit"
              ? pkg?.rider1?.phone_number
              : pkg?.rider2?.phone_number
          }
        />
      )}

      {/*    package details   */}
      <PackageDetails
        packageNumber={pkg?.receipt_no}
        customer={pkg?.customerName}
        handleViewReceipt={handleReceiptClick}
      />

      {/*    Package location  */}
      <PackageLocation
        state={pkg?.state}
        rejectReason={pkg?.rejected?.reason}
        isShelf={!pkg?.type}
        handleView={handleViewJourney}
        pkg={pkg}
      />
      
      <PacakgeETA pkgDetails={pkg}/>

      {/*    package journey   */}
      <PackageJourney handleViewJourney={handleViewJourney} showFull={false} />

      <PackageRating />
    </div>
  );
};

export default OrderDetails;
