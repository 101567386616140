import React, { useState } from "react";
import InmotionLayout from "../../layout/inmotion";
import ResetForm from "../../components/general/ResetForm";
import { useMutation } from "react-query";
import WalletServices from "../../services/WalletServices";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CustomerCareServices from "../../services/CustomerCareServices";
import { useSearchParams } from "react-router-dom";

const WalletReset = () => {
  const MySwal = withReactContent(Swal);

  let [searchParams] = useSearchParams();
  const [currentStage, setStage] = useState(1);
  const [customer_care, setCustomerCare] = useState("");
  const [state, setState] = useState({
    phone: "",
    securityQuestion: {
      id: null,
      question: "",
      answer: "",
    },
    pin: "",
    confirmPin: "",
  });

  const resetState = () => {
    setState({
      phone: "",
      securityQuestion: {
        id: null,
        question: "",
        answer: "",
      },
      pin: "",
      confirmPin: "",
    });
  };

  // console.log("SEARCH PARAMS:", searchParams.get("role"));

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSecurityQuestionChange = (id, question, answer) => {
    setState((prev) => ({
      ...prev,
      securityQuestion: {
        answer: answer ?? prev.securityQuestion.question,
        id: id ?? prev.securityQuestion.id,
        question: question ?? prev.securityQuestion.question,
      },
    }));
  };

  const handleSubmitPhone = (event) => {
    event?.preventDefault();

    getWalletSecurityQuestionMutation.mutate();
  };
  const handleSubmitSecurityQuestion = (event) => {
    event?.preventDefault();

    submitSecurityQuestionMutation.mutate();
  };
  const handleSubmitNewPin = (event) => {
    event?.preventDefault();

    submitNewPinMutation.mutate();
  };

  const getWalletSecurityQuestionMutation = useMutation({
    mutationFn: () =>
      WalletServices.fetchSecurityQuestion(
        state?.phone,
        searchParams.get("role")
      ),
    onSuccess: (res) => {
      handleSecurityQuestionChange(res?.id, res?.question, null);
      setStage((prev) => parseFloat(prev + 1));
      CustomerCareServices.fetchCustomerCareNum().then((res) => {
        setCustomerCare(res?.number);
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || error?.message);
    },
  });

  const submitSecurityQuestionMutation = useMutation({
    mutationFn: () =>
      WalletServices.answerWalletQuestion(
        state?.phone,
        state.securityQuestion.answer,
        searchParams.get("role")
      ),
    onSuccess: () => {
      setStage((prev) => parseFloat(prev + 1));
      toast.success("Security quesiton confirmed");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || error?.message);
    },
  });

  const submitNewPinMutation = useMutation({
    mutationFn: async () => {
      if (state.pin !== state.confirmPin) {
        toast.error("Pins do not match!");
        return null;
      } else
        return await WalletServices.resetWalletPin({
          phone: state?.phone,
          pin: state.pin,
          role: searchParams.get("role"),
        });
    },
    onSuccess: async () => {
      MySwal.fire({
        title: <h3>Pin reset successful!</h3>,
        icon: "success",
      }).then(() => {
        setStage(1);
        resetState();
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || error?.message);
    },
  });

  const renderStageFields = () => {
    switch (currentStage) {
      case 1:
        return {
          title: "Reset Pochi Pin",
          subtitle: "Enter your registered phone number",
          fields: [
            {
              name: "phone",
              type: "text",
              placeholder: "07XX XXX XXX",
              value: state.phone,
              required: true,
              handleChange: handleChange,
            },
          ],
          handleSubmit: handleSubmitPhone,
          isLoading: getWalletSecurityQuestionMutation.isLoading,
        };
      case 2:
        return {
          title: "Security Question",
          subtitle: "Enter answer to the security question below.",
          fields: [
            {
              name: "answer",
              type: "text",
              placeholder: "security answer",
              value: state.securityQuestion.answer,
              label: state.securityQuestion.question,
              required: true,
              handleChange: (event) =>
                handleSecurityQuestionChange(null, null, event.target.value),
            },
          ],
          options: [
            {
              title: "Forgot security answer?",
              required: true,
              handleClick: async () => {
                MySwal.fire({
                  title: (
                    <h3>
                      Please Contact Customer Care ({customer_care}) To Continue
                    </h3>
                  ),
                  icon: "success",
                }).then(() => {
                  setStage(1);
                  resetState();
                });

                handleSecurityQuestionChange(null, null, null);
              },
            },
          ],
          isLoading: submitSecurityQuestionMutation.isLoading,
          isDisabled: !state.securityQuestion.answer,
          handleSubmit: handleSubmitSecurityQuestion,
        };
      case 3:
        return {
          title: "Setup Pin",
          subtitle: "Setup your new pin",
          fields: [
            {
              name: "pin",
              type: "password",
              placeholder: "XXXX",
              maxLength: 4,
              value: state.pin,
              label: "New pin",
              required: true,
              handleChange: handleChange,
            },
            {
              name: "confirmPin",
              type: "password",
              placeholder: "XXXX",
              maxLength: 4,
              value: state.confirmPin,
              label: "Confirm new pin",
              required: true,
              handleChange: handleChange,
            },
          ],
          options: [],
          isDisabled: !state.pin || !state.confirmPin,
          isLoading: submitNewPinMutation.isLoading,
          loadingText: "Resetting Pin",
          handleSubmit: handleSubmitNewPin,
        };
      default:
        return [];
    }
  };

  return (
    <InmotionLayout
      className={"flex justify-center items-start md:items-center"}
    >
      <ResetForm {...renderStageFields()} />
    </InmotionLayout>
  );
};

export default WalletReset;
