import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/dashboard";
import Spinner from "../components/general/Spinner";
import AboutAgents from "../services/AboutAgents";
import { useQuery } from "react-query";

const AgentsList = ({ locationId, groupedAgents }) => {
  const agents = useMemo(() => {
    const found = groupedAgents.find(
      (item) => item?.location_id_id === locationId
    );
    if (found) {
      return found?.data;
    }
    return [];
  }, [locationId]);

  if (agents.length > 0) {
    return (
      <ol className="ml-8 list-disc">
        {agents.map((agent) => (
          <li className="mb-3 mt-3 " key={agent.id}>
            <Link to={`/agent/${agent.id}`}>
              <span className="font-semibold text-green-500">
                {agent.business_name}
              </span>
            </Link>
          </li>
        ))}
      </ol>
    );
  }

  return <></>;
};

const Agents = () => {
  const locationsQuery = useQuery({
    queryKey: ["locations"],
    queryFn: async () => {
      return await AboutAgents.getAgentsZones();
    },
  });
  const groupedAgentsQuery = useQuery({
    queryKey: ["grouped-agents"],
    queryFn: async () => {
      return await AboutAgents.getAgentsGrouped();
    },
  });

  return (
    <div className={"min-h-screen flex flex-col bg-white_bg "}>
      <div className="main flex-grow ">
        {locationsQuery.isLoading || groupedAgentsQuery.isLoading ? (
          <Spinner />
        ) : (
          <div>
            {Array.isArray(locationsQuery.data) &&
            locationsQuery.data.length > 0 ? (
              <div>
                <h5
                  className={
                    "mt-24 flex justify-center mb-6 md:mb-10" +
                    " font-times text-[25px] md:text-[40px]" +
                    " font-[600] leading-8 md:leading-[48px]" +
                    " text-grey_800 text-center md:text-left"
                  }
                >
                  Pickup Mtaani Agent List
                </h5>

                {locationsQuery.data.map((item) => (
                  <div className="lg:flex lg:justify-center" key={item.id}>
                    <details className="mb-2 lg:w-1/2 mx-4">
                      <summary
                        className="bg-gray-200 p-4 rounded-lg cursor-pointer
                       shadow-md mb-4"
                      >
                        <span className="font-semibold">{item.name}</span>
                      </summary>
                      <AgentsList
                        key={item.id}
                        locationId={item?.id}
                        groupedAgents={groupedAgentsQuery?.data}
                      />
                    </details>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Agents;
