import { useMap } from "react-leaflet/hooks";
import React from "react";

export const ZoomBtnAdd = ({ children, onClick }) => {
  const map = useMap();
  return (
    <button
      className={
        "h-14 w-14 bg-white rounded-xl shadow flex items-center justify-center hover:bg-gray-50"
      }
      style={{ zIndex: "999" }}
      onClick={() => map.setZoom(map.getZoom() + 1)}
    >
      {children}
    </button>
  );
};
