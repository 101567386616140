import React from "react";
import { SpinnerSvg } from "./Spinner";

const Button = ({
  text,
  className,
  handleClick,
  children,
  disabled,
  isLoading,
  loadingText,
  ...rest
}) => {
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={`p-2 px-3 rounded center ${
        disabled ? "opacity-70 cursor-not-allowed" : ""
      } ${className}`}
      {...rest}
    >
      {isLoading ? (
        <div className="flex items-center gap-2">
          {loadingText ?? "Loading "} <SpinnerSvg size="6" />
        </div>
      ) : text ? (
        text
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
