import React, { useContext, useMemo } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet-defaulticon-compatibility";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import TrackingServices from "../../services/TrackingServices";
import { Context } from "../../context/StorageContext";
import MinusSvg from "../../assets/svg/MinusSvg";
import PlusSvg from "../../assets/svg/PlusSvg";
import { MapMarker } from "./MapMarker";
import { ZoomBtnMinus } from "./ZoomBtnMinus";
import { ZoomBtnAdd } from "./ZoomBtnAdd";

/**
 * @since X.X.X
 * @Link https://pickupmtaani.atlassian.net/jira/software/c/projects/DEV/issues/DEV-279
 *
 * @param {DeliveryPackage} pkg
 * @return {JSX.Element}
 * @desc renders tracker map
 */

const Map = ({ pkgDetails }) => {
  const { search } = useLocation();
  const trackingId = search.split("?")?.at(1);
  const { setTrackingData, zoom } = useContext(Context);
  const path = useLocation();

  const { data } = useQuery({
    queryKey: ["todos", trackingId],
    queryFn: () => TrackingServices.trackPackage(trackingId, path.pathname),
    onSuccess: (res) => {
      setTrackingData(res.packageDetails);
    },
    onError: (err) => {
      toast.error(
        "Tracking error. " + err.response.data?.message || err.message
      );
    },
    retry: false,
  });

  const pkg = data?.packageDetails;

  const center = useMemo(
    () => ({
      lat: -1.2878412,
      lng: 36.8278173,
    }),
    []
  );

  const options = useMemo(
    () => ({
      //   mapId: "b181cac70f27f5e6",
      disableDefaultUI: true,
      clickableIcons: false,
      // mapId: "f2dbb0573bbff1b1",
    }),
    []
  );

  return (
    <MapContainer
      style={{
        width: "100%",
        zIndex: 0,
      }}
      center={center}
      zoom={zoom}
      zoomControl={false}
      options={options}
      className="map-container h-[43vh] md:h-screen "
      heading={parseInt(pkg?.location?.heading) || 0}
    >
      <MapMarker pkgDetails={pkgDetails} />
      {/* add google map tile url  */}
      {/*{Object.values(coordinates)?.map((c) => (*/}
      {/*))}*/}
      <TileLayer
        attribution="Google Maps"
        url="https://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
      />

      <div
        className={
          "p-1 m-2 rounded-xl flex flex-col gap-y-2 shadow-lg bg-gray-50"
        }
        style={{ position: "absolute", zIndex: "999", left: 0, bottom: 0 }}
      >
        {/*  + button  */}
        <ZoomBtnAdd onClick={1}>
          <PlusSvg />
        </ZoomBtnAdd>
        {/*    - Button   */}
        <ZoomBtnMinus onClick={1}>
          <MinusSvg />
        </ZoomBtnMinus>
      </div>
    </MapContainer>
  );
};

export default Map;
