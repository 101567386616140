export const SubmitButton = ({text, onClick, transform='uppercase', disabled, children, className, ...rest})=>{
  return (
    <button
    onClick={onClick}
    disabled = {disabled}
    className={`w-full bg-primary text-[black] 
    py-2 rounded-full hover:opacity-60
    ${transform} ${disabled?'opacity-60': ''} ${className} `}
    {...rest}
    > {children?children: text} </button>
  )
}
